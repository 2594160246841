import React, { useState, useEffect } from 'react';
import '../TeleHealth.css';
import './DoctorsList.css';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import CreateDoctor from './CreateDoctor.js';
import UpdateDoctor from './UpdateDoctor.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import NoImage from '../../TeleHealth/Images/NoImage.png';

export default function DoctorsList() {
	const UAToken = localStorage.getItem("accessToken");
	const user_Role = localStorage.getItem('userRole');
	const [uRole, setURole] = useState(user_Role);
	const navigate = useNavigate();

	const [showEditMode, setShowEditMode] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const [data, setData] = useState([]);
	const [showAddMode, setShowAddMode] = useState(false);
	const [getReloadData, setGetReloadData] = useState(false);
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const [doctorType, setDoctorType] = useState("Doctors");
	const [searchTerm, setSearchTerm] = useState('');
	const [loading, setLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const apiEndPoint = 'https://ciaitelehealth.com/api';

	const doctorTypes = [
		{ label: 'Doctors', value: 'Doctors' },
		{ label: 'Pending Doctors', value: 'Pending Doctors' }
	];

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/doctorsList');
		} else {
			navigate('/')
		}
	}, [UAToken, navigate]);

	useEffect(() => {
		const token = localStorage.getItem('accessToken');
		if (!!token) {
			loadDoctorsList(1, pageSize);
		}
	}, []);

	useEffect(() => {
		if (getReloadData) {
			loadDoctorsList(currentPage, pageSize);
			setGetReloadData(false);
		}
	}, [getReloadData]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setCurrentPage(1);
			loadDoctorsList(1, pageSize);
		}, 500);

		return () => clearTimeout(timeoutId);
	}, [searchTerm]);

	useEffect(() => {
		setCurrentPage(1);
		loadDoctorsList(1, pageSize);
	}, [doctorType]);

	const loadDoctorsList = async (page, size) => {
		try {
			setLoading(true);
			const token = localStorage.getItem('accessToken');
			const endpoint = doctorType === "Doctors"
				? `${apiEndPoint}/doctors/get-doctors-list`
				: `${apiEndPoint}/doctors/get-pending-doctors-list`;

			const response = await fetch(
				`${endpoint}?search=${searchTerm}&page=${page}&pageSize=${size}`,
				{
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Content-Type': 'application/json'
					},
				}
			);

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const result = await response.json();
			if (result && result.data) {
				setData(result.data);
				setTotalRecords(result.total || 0);
			} else {
				toast.error("Invalid data format received");
			}
		} catch (error) {
			console.error('Error fetching data:', error);
			toast.error("Failed to fetch doctors list");
		} finally {
			setLoading(false);
		}
	};

	const onPage = (event) => {
		const newPage = event.page + 1;
		const newSize = event.rows;
		setCurrentPage(newPage);
		setPageSize(newSize);
		loadDoctorsList(newPage, newSize);
	};

	const handleChange = () => {
		setIsActive(!isActive);
	}

	const handleDoctorsChange = (e) => {
		setDoctorType(e.value);
		setSearchTerm('');
	}

	const deleteConfirm = (doctorId) => {
		confirmDialog({
			message: 'Are you sure you want to delete this Doctor?',
			header: 'Confirmation',
			accept: () => deleteDoctor(doctorId),
		});
	}

	const reviewConfirm = (data) => {
		localStorage.setItem('doctorDetails', JSON.stringify(data));
		navigate(`/reviewDoctor/${data.id}`);
	}

	const slotsConfirm = (slotId, slotName, data) => {
		setSelectedRowItem(data);
		navigate(`/slotpage/${slotId}/${slotName}/${data.id}`);
	}

	const statusBodyTemplate = (data) => {
		return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
	};

	const getSeverity = (status) => {
		switch (status) {
			case 'Active':
				return 'active-status';
			case 'In-Active':
				return 'In-active-status';
			default:
				return '';
		}
	};

	const imageBodyTemplate = (data) => {
		return (
			<img
				height={60}
				width={60}
				src={data.image || NoImage}
				alt={data.name || "Doctor image"}
				style={{ objectFit: 'cover', borderRadius: '4px' }}
			/>
		);
	}

	const deleteDoctor = async (id) => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/doctors/delete-doctor/${id}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error('Failed to delete doctor');
			}

			const data = await response.json();
			if (data.msg === 'success') {
				toast.success("Doctor deleted successfully");
				loadDoctorsList(currentPage, pageSize);
			} else {
				toast.error(data.message || "Failed to delete doctor");
			}
		} catch (error) {
			console.error('Error deleting doctor:', error);
			toast.error("Failed to delete doctor");
		}
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<div className="action-buttons">
				{doctorType === "Pending Doctors" && (
					<Button
						icon="pi pi-eye"
						rounded
						outlined
						className="p-button-info mr-2"
						title="Review"
						onClick={() => reviewConfirm(rowData)}
					/>
				)}

				{(user_Role === "clinic" && doctorType === "Doctors") && (
					<Button
						icon="pi pi-calendar"
						rounded
						outlined
						className="p-button-success mr-2"
						title="Slots"
						onClick={() => slotsConfirm(rowData.id, rowData.name, rowData)}
					/>
				)}

				<Button
					icon="pi pi-pencil"
					rounded
					outlined
					className="p-button-warning mr-2 editBtnFocus"
					title="Edit"
					onClick={() => {
						setSelectedUserId(rowData);
						setShowEditMode(true);
					}}
				/>

				<Button
					icon="pi pi-trash"
					rounded
					outlined
					className="p-button-danger deleteBtnFocus"
					title="Delete"
					onClick={() => deleteConfirm(rowData.id)}
				/>
			</div>
		);
	};

	return (
		<div className='dashboard'>
			<Sidebar isActive={isActive} />
			<div className='dashboardContainer'>
				<Navbar isActive={isActive} handleChange={handleChange} />
				<div>
					<div className='users-page'>
						<div className='container'>
							<div className='users-list'>
								<div className='list-header'>
									<div className='header-users'>
										<h6>{doctorType} List</h6>
									</div>
									<div className='header-actions'>
										<Dropdown
											value={doctorType}
											options={doctorTypes}
											onChange={handleDoctorsChange}
											placeholder="Select Doctor Type"
											className="doctor-type-dropdown"
										/>
										<span className="p-input-icon-left search-input">
											<i className="pi pi-search" />
											<InputText
												placeholder="Search doctors"
												value={searchTerm}
												onChange={(e) => setSearchTerm(e.target.value)}
											/>
										</span>
										<button
											className='addUserCls'
											title='Add Doctor'
											onClick={() => setShowAddMode(true)}
										>
											<i className='pi pi-plus' style={{ paddingRight: '6px' }}></i>
											Create
										</button>
									</div>
								</div>

								<DataTable
									value={data}
									lazy
									paginator
									first={(currentPage - 1) * pageSize}
									rows={pageSize}
									totalRecords={totalRecords}
									onPage={onPage}
									loading={loading}
									rowsPerPageOptions={[5, 10, 20, 50]}
									emptyMessage="No doctors found"
									paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
									currentPageReportTemplate="Showing {first} to {last} of {totalRecords} doctors"
									className="doctors-table"
								>

									<Column
										field="image"
										header="Image"
										body={imageBodyTemplate}
										style={{ width: '10%' }}
									/>
									<Column
										field="name"
										header="Name"
										sortable
										filter
										style={{ width: '25%' }}
									/>
									<Column
										field="email"
										header="Email"
										sortable
										filter
										style={{ width: '25%' }}
									/>
									<Column
										field="specialization"
										header="Specialization"
										sortable
										filter
										style={{ width: '20%' }}
									/>
									<Column
										header="Actions"
										body={actionBodyTemplate}
										style={{ width: '10%', textAlign: 'center' }}
									/>
								</DataTable>
							</div>
						</div>
					</div>

					<Dialog
						header="Create New Doctor"
						visible={showAddMode}
						style={{ width: '40vw' }}
						onHide={() => setShowAddMode(false)}
					>
						<CreateDoctor
							visible={showAddMode}
							userId={selectedUserId}
							setGetReloadData={setGetReloadData}
							setUserAdded={() => setShowAddMode(false)}
						/>
					</Dialog>

					<Dialog
						header="Update Doctor"
						visible={showEditMode}
						style={{ width: '40vw' }}
						onHide={() => setShowEditMode(false)}
					>
						<UpdateDoctor
							visible={showEditMode}
							updateDoctordata={selectedUserId}
							setUserEdited={() => setShowEditMode(false)}
						/>
					</Dialog>

					<ConfirmDialog />
					<ToastContainer />
				</div>
			</div>
		</div>
	);
}
