import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import '../../TeleHealth/TeleHealth.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateClinics(props) {
	const { setGetReloadData } = props;
	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';

	const [errors, setErrors] = useState({
		name: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		zipcode: "",
		contactNumber1: "",
		contactNumber2: "",
		contactName: ""
	});

	const [initialValues, setInitialValues] = useState({
		name: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		zipcode: '',
		contactNumber1: '',
		contactNumber2: '',
		contactName: '',
		isPhc: false
	});

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		setInitialValues(prev => ({
			...prev,
			[name]: type === 'checkbox' ? checked : value
		}));
		validateField(name, type === 'checkbox' ? checked : value);
	};

	const validateField = (name, value) => {
		let error = "";
		const numberPattern = /^[0-9]+$/;
		const phonePattern = /^[0-9]{10}$/;

		switch(name) {
			case 'name':
			case 'city':
			case 'state':
			case 'contactName':
				if (!value) {
					error = `Enter ${name.charAt(0).toUpperCase() + name.slice(1)}`;
				} else if (value.length < 3) {
					error = "Please enter at least 3 characters";
				} else if (value.length > 50) {
					error = "Please enter less than 50 characters";
				}
				break;

			case 'address1':
			case 'address2':
				if (!value) {
					error = "Enter Address";
				} else if (value.length < 10) {
					error = "Please enter at least 10 characters";
				} else if (value.length > 70) {
					error = "Please enter less than 70 characters";
				}
				break;

			case 'zipcode':
				if (!value) {
					error = "Enter Zip Code";
				} else if (!numberPattern.test(value)) {
					error = "Only numbers allowed";
				} else if (value.length !== 6) {
					error = "Zip code must be 6 digits";
				}
				break;

			case 'contactNumber1':
			case 'contactNumber2':
				if (!value) {
					error = "Enter Contact Number";
				} else if (!phonePattern.test(value)) {
					error = "Enter valid 10-digit number";
				}
				break;

			default:
				break;
		}

		setErrors(prev => ({
			...prev,
			[name]: error
		}));

		return error;
	};

	const validateForm = () => {
		const newErrors = {};
		let isValid = true;

		Object.keys(initialValues).forEach(key => {
			if (key !== 'isPhc') {  // Skip validation for checkbox
				const error = validateField(key, initialValues[key]);
				if (error) {
					isValid = false;
					newErrors[key] = error;
				}
			}
		});

		setErrors(newErrors);
		return isValid;
	};

	const handleSubmit = async () => {
		if (!validateForm()) {
			return;
		}

		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/clinics/create-clinic`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(initialValues),
			});

			const data = await response.json();
			
			if (data.msg === "Clinic created successfully") {
				toast.success("Clinic created successfully");
				setGetReloadData(true);
				props.setUserAdded();
				setTimeout(() => navigate('/ClinicsList'), 2000);
			} else {
				toast.error(data.message || "Failed to create clinic");
			}
		} catch (error) {
			console.error('Error creating clinic:', error);
			toast.error("Failed to create clinic");
		}
	};

	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<form>
						<div className="form-group">
							<label>Name</label>
							<input 
								type="text" 
								className="form-control" 
								placeholder="Enter Name" 
								name="name" 
								value={initialValues.name} 
								onChange={handleInputChange} 
							/>
							<div className="error">{errors.name}</div>
						</div>

						<div className="form-group">
							<label>Address 1</label>
							<input 
								type="text" 
								className="form-control" 
								placeholder="Enter Address 1" 
								name="address1" 
								value={initialValues.address1} 
								onChange={handleInputChange} 
							/>
							<div className="error">{errors.address1}</div>
						</div>

						<div className="form-group">
							<label>Address 2</label>
							<input 
								type="text" 
								className="form-control" 
								placeholder="Enter Address 2" 
								name="address2" 
								value={initialValues.address2} 
								onChange={handleInputChange} 
							/>
							<div className="error">{errors.address2}</div>
						</div>

						<div className="form-group">
							<label>City</label>
							<input 
								type="text" 
								className="form-control" 
								placeholder="Enter City" 
								name="city" 
								value={initialValues.city} 
								onChange={handleInputChange} 
							/>
							<div className="error">{errors.city}</div>
						</div>

						<div className="form-group">
							<label>State</label>
							<input 
								type="text" 
								className="form-control" 
								placeholder="Enter State" 
								name="state" 
								value={initialValues.state} 
								onChange={handleInputChange} 
							/>
							<div className="error">{errors.state}</div>
						</div>

						<div className="form-group">
							<label>Zip Code</label>
							<input 
								type="text" 
								className="form-control" 
								placeholder="Enter Zip Code" 
								name="zipcode" 
								value={initialValues.zipcode} 
								onChange={handleInputChange} 
							/>
							<div className="error">{errors.zipcode}</div>
						</div>

						<div className="form-group">
							<label>Contact Number 1</label>
							<input 
								type="text" 
								className="form-control" 
								placeholder="Enter Contact Number 1" 
								name="contactNumber1" 
								value={initialValues.contactNumber1} 
								onChange={handleInputChange} 
							/>
							<div className="error">{errors.contactNumber1}</div>
						</div>

						<div className="form-group">
							<label>Contact Number 2</label>
							<input 
								type="text" 
								className="form-control" 
								placeholder="Enter Contact Number 2" 
								name="contactNumber2" 
								value={initialValues.contactNumber2} 
								onChange={handleInputChange} 
							/>
							<div className="error">{errors.contactNumber2}</div>
						</div>

						<div className="form-group">
							<label>Contact Name</label>
							<input 
								type="text" 
								className="form-control" 
								placeholder="Enter Contact Name" 
								name="contactName" 
								value={initialValues.contactName} 
								onChange={handleInputChange} 
							/>
							<div className="error">{errors.contactName}</div>
						</div>

						<div className="checkbox-group">
							<label htmlFor="isPhc">Is PHC</label>
							<input
								type="checkbox"
								id="isPhc"
								name="isPhc"
								checked={initialValues.isPhc}
								onChange={handleInputChange}
							/>
						</div>

						<div className="button-container">
							<button 
								type="button" 
								className="button-cancle-cls"
								onClick={() => props.setUserAdded()}
							>
								Cancel
							</button>
							<button 
								type="button" 
								className="button-savecls"
								onClick={handleSubmit}
							>
								Save
							</button>
						</div>
					</form>
				</div>
			</div>
			<ToastContainer />
		</div>
	)
}

export default CreateClinics;
