import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputSwitch } from 'primereact/inputswitch';


function UpdateSpecialization(props) {
	const { updateData, setGetReloadData } = props;

	const updateStatus = updateData && updateData?.status === 'Active' ? true :
		updateData && updateData?.status === 'In-Active' ? false : false;

	const [questStatus, setquestStatus] = useState(updateStatus);
	const [errorSpecializationNameMessage, setErrorSpecializationNameMessage] = useState("");


	const [initialValues, setInitialValues] = useState({
		specialization: updateData ? updateData?.specialization : '',
		status: updateData ? updateData?.status : ''

	});
	const [file, setFile] = useState(null);
	const [errors, setErrors] = useState({});
	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';

	const UpdateSpecializationInfo = async () => {

		if (initialValues.specialization === '') {
			validationForm('specialization', initialValues.specialization);
		} else {
			const url = `${apiEndPoint}/specializations/update-specialization`;
			// const formData = {
			// 	specialization: initialValues.specialization,
			// 	id: updateData?.id,
			// 	status: questStatus === true ? 'Active' : 'In-Active'
			// 	// status: updateData?.status
			// };
			const token = localStorage.getItem('accessToken');
			// const options = {
			// 	method: 'PUT',
			// 	headers: {
			// 		'Authorization': `Bearer ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// 	body: JSON.stringify(formData),
			// };
			var bodyFormData = new FormData();
			bodyFormData.append('specialization', updateData?.specialization);
			bodyFormData.append('id', updateData?.id);
			bodyFormData.append('status', questStatus === true ? 'Active' : 'In-Active');
			bodyFormData.append('image', file, file.name); 


			const options = {
				method: 'PUT',					
				headers: {
					'Authorization': `Bearer ${token}`,
					//'Content-Type': 'application/json',
					//'Content-Type': 'multipart/form-data',
					//'Content-Type': 'undefined'					
				},
				body: bodyFormData,
			};	


			await fetch(url, options)
				.then(response => response.json())
				.then(data => {
					if (data.msg === 'Specialization details updated successfully') {
						setInitialValues({
							...initialValues,
							specialization: '',
							status: ''
						});

						setTimeout(() => {
							navigate('/specializationsList');
						}, 1000)
						props.setUserEdited();
						setGetReloadData(true);
						toast.success("Specialization Updated Successfully.");
					}
				})
				.catch(error => console.error('Error:', error));
		}
	}

	const validationForm = (type, value) => {

		let errorMessage = "";
		if (type === "specialization") {
			if (value === "") {
				errorMessage = "Enter Specialization Name ";
			}
			else if (value.length < 3) {
				errorMessage = "Please enter atleast 3 Characters";
			}

			else if (value.length > 20) {
				errorMessage = "Please enter less than 20 Characters only";
			}
			setErrorSpecializationNameMessage(errorMessage)
			setAllErrors({ ...allErrors, ['specializationNameErr']: errorMessage });
		}
	}

	const handleUploadImage = (event) => {
		const selectedFile = event.target.files[0];
		if (selectedFile) {
			const validTypes = ['image/jpeg', 'image/png','image/svg+xml'];
			const maxSize = 2 * 1024 * 1024; // 2MB
			if (!validTypes.includes(selectedFile.type)) {
				setErrors({ ...errors, image: 'Please upload an image in JPEG or PNG format.' });
				return;
			}
			if (selectedFile.size > maxSize) {
				setErrors({ ...errors, image: 'Please upload an image smaller than 2MB.' });
				return;
			}
			setErrors({ ...errors, image: '' });
			setFile(selectedFile);
		}
	};


	const [allErrors, setAllErrors] = useState({
		specializationNameErr: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInitialValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
		validationForm(name, value)
	};

	const handleChangeSwitch = (e) => {
		console.log(!questStatus)
		setquestStatus(!questStatus)
	};

	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<div>
						<p>
							<span>Specialization Name</span>
							<input
								type='text'
								className='form-control'
								placeholder='Enter Specialization Name'
								name='specialization'
								value={initialValues.specialization}
								onChange={handleChange}
								// onChange={e => setInitialValues({ ...initialValues, specialization: e.target.value })}
							/>
							<div className='errorSpName'>{errorSpecializationNameMessage}</div>
						</p>
					</div>
					<div>
						<p>
							<label>Image</label><br />
							<input
								type="file"
								onChange={handleUploadImage}
							/>
							<div className='error'>{errors.image}</div> 
						</p>
					</div>
					<div style={{ display: 'flex' }}>
						<div style={{ paddingLeft: '2px' }}>Status</div>
						<div style={{ paddingLeft: '10px' }}><InputSwitch inputId="input-metakey" checked={questStatus} onChange={handleChangeSwitch} /></div>
					</div>
				</div>
			</div>
			<div className='specialBtnCls button-container'>
				<button type="button" className="button-cancle-cls" style={{ marginRight: '15px' }} onClick={() => props.setUserEdited()}>Cancel</button>
				<button type="button" className="button-savecls" onClick={() => UpdateSpecializationInfo()}>Update</button>
			</div>
			{<ToastContainer />}
		</div>
	)
}

export default UpdateSpecialization;