import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MenuProps, useStyles, options } from "./utils";
import { useNavigate } from 'react-router-dom';
import '../TeleHealth.css';
import { format } from 'date-fns-tz';
import TimezoneSelect, { allTimezones } from 'react-timezone-select'
import 'react-datetime-picker/dist/DateTimePicker.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import addDays from 'date-fns/addDays'
import { Placeholder, Stack } from 'rsuite';

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';


export default function BulkSlotPage(props) {
    console.log('BulkSlotPage props:', props);

    const { setGetReloadData, setUserBulked, doctorId, doctorName, clinicId } = props;
    const classes = useStyles();
    const [avalibleSlot, setAvalibleSlot] = useState([]);
    const [currentTime, setCurrentTime] = useState(null);
    const [value, setValue] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const [timeZone, setTimeZone] = useState([]);
    const [selected, setSelected] = useState("");

    const [errorConsultationType, setErrorConsultationType] = useState("");
    const [errorDoctorTimezone, setErrorDoctorTimezone] = useState("");
    const [errorStartDate, setErrorStartDate] = useState("");
    const [errorEndDate, setErrorEndDate] = useState("");
    const [errorStartTime, setErrorStartTime] = useState("");
    const [errorEndTime, setErrorEndTime] = useState("");

    useEffect(() => {
        console.log('Current doctorId:', doctorId);
    }, [doctorId]);

    const navigate = useNavigate();
    const apiEndPoint = 'https://ciaitelehealth.com/api';

    const [initialValues, setInitialValues] = useState({
        consultationType: "",
        startDate: null,
        endDate: null,
        startTime: "",
        endTime: "",
        doctorTimeZone: "",
    });

    useEffect(() => {
        setAvalibleSlot(options.slots)
    }, [avalibleSlot])

    useEffect(() => {
        const fetchTime = () => {
            const timezone = 'Asia/Kolkata';
            const now = new Date();
            const formattedTime = format(now, 'MM/dd/yyyy HH:mm:ss', { timeZone: timezone });
            setCurrentTime(formattedTime);
        };

        fetchTime();

        const interval = setInterval(fetchTime, 1000);

        return () => clearInterval(interval);
    }, []);


    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    const formatTime = (timeString) => {
        const options = {
          hour: '2-digit',
          minute: '2-digit',
          pm: 'PM',
          am: 'AM',
        };
        const time = new Date(timeString);
        return time.toLocaleTimeString([], options);
      };

    const validationForm = (type, value) => {
        let errorMessage = "";
        if (type === 'consultationType') {
            if (value === '') {
                errorMessage = 'Select Consultation Type';
            }
            setErrorConsultationType(errorMessage);
            setAllErrors({ ...allErrors, ['consultationTypeErr']: errorMessage })
        }
        if (type === 'doctorTimeZone') {
            if (value === '') {
                errorMessage = 'Select Doctor Timezone';
            }
            setErrorDoctorTimezone(errorMessage);
            setAllErrors({ ...allErrors, ['doctorTimezoneErr']: errorMessage })
        }

        if (type === 'startDate') {
            if (selected === '') {
                errorMessage = 'Select Start Date';
            }
            setErrorStartDate(errorMessage);
            setAllErrors({ ...allErrors, ['startDateErr']: errorMessage })
        }
        if (type === 'endDate') {
            if (selected === '') {
                errorMessage = 'Select End Date';
            }
            setErrorEndDate(errorMessage);
            setAllErrors({ ...allErrors, ['endDateErr']: errorMessage })
        }
        if (type === 'startTime') {
            if (selected === '') {
                errorMessage = 'Select Start Time';
            }
            setErrorStartTime(errorMessage);
            setAllErrors({ ...allErrors, ['startTimeErr']: errorMessage })
        }
        if (type === 'endTime') {
            if (selected === '') {
                errorMessage = 'Select End Time';
            }
            setErrorEndTime(errorMessage);
            setAllErrors({ ...allErrors, ['endTimeErr']: errorMessage })
        }
    }

    const [allErrors, setAllErrors] = useState({
        consultationTypeErr: "",
        doctorTimezoneErr: "",
        startDateErr: "",
        endDateErr: "",
        startTimeErr: "",
        endTimeErr: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInitialValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        validationForm(name, value)
    };

    const BulkSlotData = async () => {
        console.log('doctorId before request:', doctorId);
        
        if (!doctorId) {
            console.error('doctorId is missing');
            toast.error('Doctor ID is required');
            return;
        }

        let formatStartDate = startDate !== null ? formatDate(startDate) : null;
        let formatEndDate = endDate !== null ? formatDate(endDate) : null;

        let formatStartTime = startTime !== null ? formatTime(startTime) : null;
        let formatEndTime = endTime !== null ? formatTime(endTime) : null;

        if (startDate === null || endDate === null || initialValues.consultationType === "" ||
            initialValues.doctorTimeZone === "" || startTime === null || endTime === null
        ) {
            validationForm('consultationType', initialValues.consultationType);
            validationForm('doctorTimeZone', initialValues.doctorTimeZone);
            validationForm('startDate', startDate);
            validationForm('endDate', endDate);
            validationForm('startTime', startTime);
            validationForm('endTime', endTime);
        }
        else {
            const url = `${apiEndPoint}/slots/create-bulk-slots-by-doctor`;

            const formData = {
                doctorId: doctorId,
                clinicId: clinicId,
                startDate: formatStartDate,
                endDate: formatEndDate,
                startTime: formatStartTime,
                endTime: formatEndTime,
                consultationType: initialValues.consultationType,
                doctorTimeZone: initialValues.doctorTimeZone
            };

            console.log('Submitting form data:', formData);

            const token = localStorage.getItem('accessToken');

            const options = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            };

            try {
                const response = await fetch(url, options);
                const data = await response.json();
                console.log('API Response:', data);

                if (data.msg === 'Bulk created successfully') {
                    setInitialValues({
                        ...initialValues,
                        startDate: null,
                        endDate: null,
                        startTime: null,
                        endTIme: null,
                        consultationType: '',
                        doctorTimeZone: ''
                    });

                    // Close modal immediately
                    props.setUserBulked();
                    setGetReloadData(true);
                    toast.success('Bulk created successfully');

                    // Navigate after delay
                    setTimeout(() => {
                        navigate(`/slotpage/${doctorId}/${doctorName}/${doctorId}`);
                    }, 2000);
                }
            } catch (error) {
                console.error('API Error:', error);
                toast.error('Failed to create bulk slots');
            }
        }
    }


    const handleStartDate = (date) => {
        setStartDate(date);
        validationForm(selected)
        setErrorStartDate("");
    };

    const handleEndDate = (date) => {
        setEndDate(date);
        validationForm(selected)
        setErrorEndDate("");
    };

    const handleStartTime = (time) => {
        setStartTime(time);
        validationForm(selected)
        setErrorStartTime("");
    };

    const handleEndTime = (time) => {
        setEndTime(time);
        validationForm(selected)
        setErrorEndTime("");
    };

    return (
        <>
            <div className='create-slot-container'>
                <div style={{ marginLeft: "1px", display: 'flex' }}>
                    <div className="flex-auto" style={{ paddingRight: '0px', marginLeft: '-8px' }} >
                        <label htmlFor="calendar-24h" className="font-bold block mb-2" style={{ marginLeft: "27px" }}>
                            Start Date:
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={[
                                    'DesktopDatePicker',
                                ]}
                            >
                                <DemoItem>
                                    <DesktopDatePicker value={startDate} format="MM/DD/YYYY" onChange={(value) => handleStartDate(value)}
                                        slotProps={{
                                            textField: {
                                                InputProps: {
                                                    placeholder: "Enter Start Date"
                                                },
                                            }
                                        }} />
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>                        
                        <div className='errorStart'>{errorStartDate}</div>
                    </div>

                    <div className="flex-auto" style={{ paddingRight: '10px', marginLeft: '4px' }}>
                        <label htmlFor="calendar-12h" className="font-bold block mb-2" style={{ marginLeft: "27px" }}>
                            End Date:
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={[
                                    'DesktopDatePicker',
                                ]}
                            >
                                <DemoItem>
                                    <DesktopDatePicker value={endDate} format="MM/DD/YYYY" onChange={(value) => handleEndDate(value)} slotProps={{
                                        textField: {
                                            InputProps: {
                                                placeholder: "Enter End Date"
                                            },
                                        }
                                    }} />
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>
                        <div className='errorEnd'>{errorEndDate} </div>
                    </div>
                </div>
                <div style={{ marginLeft: "1px", display: 'flex' }}>
                    <div className="flex-auto"
                        style={{ paddingRight: '-5px', marginLeft: '-8px' }}
                    >
                        <label htmlFor="calendar-24h" className="font-bold block mb-2" style={{ marginLeft: "27px" }}>
                            Start Time:
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={[                                    
                                    'DesktopTimePicker',                                    
                                ]}
                            >
                                <DemoItem>
                                    <DesktopTimePicker value={startTime} timeFormat="HH:mm" onChange={(value) => handleStartTime(value)} slotProps={{
                                        textField: {
                                            InputProps: {
                                               placeholder: "Enter Start Time"
                                            },
                                        }
                                    }}/>
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>
                        <div className='errorStart'>{errorStartTime}</div>
                    </div>

                    <div className="flex-auto" style={{ paddingRight: '10px', marginLeft: '4px' }}>
                        <label htmlFor="calendar-12h" className="font-bold block mb-2" style={{ marginLeft: "27px" }}>
                            End Time:
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={[                                    
                                    'DesktopTimePicker',                                    
                                ]}
                            >
                                <DemoItem>
                                    <DesktopTimePicker value={endTime} timeFormat="HH:mm" onChange={(value) => handleEndTime(value)} slotProps={{
                                        textField: {
                                            InputProps: {
                                               placeholder: "Enter End Time"
                                            },
                                        }
                                    }}/>
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>
                        <div className='errorEnd'>{errorEndTime} </div>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="consultationType" style={{ marginLeft: "18px" }}>Consultation Type:</label>
                    <select name="consultationType" value={initialValues.consultationType} onChange={handleChange} className="doctorsListCls form-control"
                        style={{ display: 'flex', width: '97%' }}>
                        <option value="">Select</option>
                        <option value="online">Online</option>
                        <option value="offline">Offline</option>
                    </select>
                    <div className='errorType'>{errorConsultationType}</div>
                </div>

                <div className="form-group" style={{ width: '97%', marginInlineStart: '5px' }}>
                    <label htmlFor="doctorTimeZone" style={{ marginLeft: "13px" }}>Doctor TimeZone:</label>
                    <select name='doctorTimeZone' value={initialValues.doctorTimeZone} onChange={handleChange} className="doctorsListCls form-control"
                        style={{ display: 'flex', width: '100%', marginLeft: '1px' }}>
                        <option value="">Select</option>
                        <option value="Asia/Kolkata">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                        <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                        <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                        <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                        <option value="Asia/Baku">(GMT+04:00) Baku</option>
                        <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                        <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                        <option value="US/Alaska">(GMT-09:00) Alaska</option>
                        <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                        <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                        <option value="US/Arizona">(GMT-07:00) Arizona</option>
                        <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                    </select>
                    <div className='errorTimezone'>{errorDoctorTimezone}</div>
                </div>

                <div style={{ textAlign: 'center', marginLeft: '110px' }}>
                    <Stack direction="row" spacing={2}>
                        <Button className='buttonSlotAlignCls2' variant="contained" onClick={() => BulkSlotData()} >Save</Button>
                        <Button className='buttonSlotAlignCls3' variant="contained" style={{ marginTop: "-120px", marginLeft: "-50px" }} onClick={() => props.setUserBulked()} >Clear</Button>
                    </Stack>
                </div>
                <ToastContainer />
            </div >
        </>
    );
}
