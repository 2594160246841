import React, { useState, useEffect } from 'react';
import '../../TeleHealth/TeleHealth.css';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import { toast } from "react-toastify";

function CreateDoctor(props) {
	const { setGetReloadData } = props;
	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';
	const UAToken = localStorage.getItem("accessToken");

	const [isActive, setIsActive] = useState(false);
	const [specializationList, setSpecializationList] = useState([]);
	const [QualificationsList, setQualificationList] = useState([]);
	const [selectedOption, setSelectedOption] = useState("");
	const [isOfflineAvailable, setIsOfflineAvailable] = useState(false);
	const [isOnlineAvailable, setIsOnlineAvailable] = useState(false);
	const [medicationProof, setMedicationProof] = useState(null);
	const [identityProof, setIdentityProof] = useState(null);

	const [errors, setErrors] = useState({
		name: "",
		bio: "",
		phoneNumber: "",
		email: "",
		consultationFee: "",
		gender: "",
		age: "",
		offlineCheckbox: "",
		onlineCheckbox: "",
		specializations: "",
		experience: "",
		qualificationId: "",
		college: "",
		location: "",
		fromYear: "",
		toYear: "",
		medicationProof: "",
		identityProof: ""
	});

	const [initialValues, setInitialValues] = useState({
		name: '',
		bio: '',
		phoneNumber: '',
		email: '',
		isOfflineConsultationAvailable: false,
		isOnlineConsultationAvailable: false,
		consultationFee: '',
		gender: '',
		age: '',
		specializations: [{ id: '', experience: '' }],
		qualifications: [{ id: '', college: '', location: '', fromYear: '', toYear: '' }]
	});

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/createdoctor');
		} else {
			navigate('/')
		}
	}, [UAToken, navigate]);

	useEffect(() => {
		fetchSpecializations();
		fetchQualifications();
	}, [])

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setInitialValues(prev => ({
			...prev,
			[name]: value
		}));
		validateField(name, value);
	};

	const handleFileChange = (e, type) => {
		const file = e.target.files[0];
		if (file) {
			if (type === 'medication') {
				setMedicationProof(file);
				setErrors(prev => ({ ...prev, medicationProof: "" }));
			} else {
				setIdentityProof(file);
				setErrors(prev => ({ ...prev, identityProof: "" }));
			}
		}
	};

	const handleCheckbox = (type) => (event) => {
		const { checked } = event.target;
		if (type === 'online') {
			setIsOnlineAvailable(checked);
			setInitialValues(prev => ({
				...prev,
				isOnlineConsultationAvailable: checked
			}));
		} else {
			setIsOfflineAvailable(checked);
			setInitialValues(prev => ({
				...prev,
				isOfflineConsultationAvailable: checked
			}));
		}
		
		setErrors(prev => ({
			...prev,
			[type === 'online' ? 'onlineCheckbox' : 'offlineCheckbox']: ""
		}));
	};

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
		setErrors(prev => ({ ...prev, gender: "" }));
	};

	const handleArrayInputChange = (e, arrayName, index) => {
		const { name, value } = e.target;
		setInitialValues(prev => ({
			...prev,
			[arrayName]: [
				...prev[arrayName].slice(0, index),
				{
					...prev[arrayName][index],
					[name.split('.')[1]]: value
				},
				...prev[arrayName].slice(index + 1)
			]
		}));
		validateField(name, value);
	};

	const validateField = (name, value) => {
		let error = "";
		const numberPattern = /^[0-9]+$/;
		const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
		const phonePattern = /^[0-9]{10}$/;

		switch(name) {
			case 'name':
				if (!value) error = "Enter Name";
				else if (value.length < 3) error = "Enter at least 3 characters";
				else if (value.length > 20) error = "Enter less than 20 characters";
				break;
			case 'bio':
				if (!value) error = "Enter Bio";
				else if (value.length < 10) error = "Enter at least 10 characters";
				else if (value.length > 20) error = "Enter less than 20 characters";
				break;
			case 'phoneNumber':
				if (!value) error = "Enter Phone Number";
				else if (!phonePattern.test(value)) error = "Enter valid phone number";
				break;
			case 'email':
				if (!value) error = "Enter Email";
				else if (!emailPattern.test(value)) error = "Enter valid email";
				break;
			case 'consultationFee':
			case 'age':
			case 'specializations[0].experience':
				if (!value) error = `Enter ${name === 'consultationFee' ? 'Consultation Fee' : name === 'age' ? 'Age' : 'Experience'}`;
				else if (!numberPattern.test(value)) error = "Only numbers allowed";
				else if (value.length > (name === 'consultationFee' ? 5 : 2)) error = `Maximum ${name === 'consultationFee' ? 5 : 2} digits allowed`;
				break;
			default:
				if (name.includes('qualifications[0].')) {
					const field = name.split('.')[1];
					if (!value) error = `Enter ${field.charAt(0).toUpperCase() + field.slice(1)}`;
				}
		}

		setErrors(prev => ({ ...prev, [name]: error }));
	};

	const fetchSpecializations = async () => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/specializations/get-active-specializations-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});
			
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			
			const data = await response.json();
			if (data && Array.isArray(data.data)) {
				setSpecializationList(data.data);
			} else {
				console.error('Invalid specializations data format:', data);
				toast.error("Invalid specializations data format");
			}
		} catch (error) {
			console.error('Error fetching specializations:', error);
			toast.error("Failed to load specializations");
		}
	};

	const fetchQualifications = async () => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/qualifications/get-active-qualifications-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});
			
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			
			const data = await response.json();
			if (data && Array.isArray(data.data)) {
				setQualificationList(data.data);
			} else {
				console.error('Invalid qualifications data format:', data);
				toast.error("Invalid qualifications data format");
			}
		} catch (error) {
			console.error('Error fetching qualifications:', error);
			toast.error("Failed to load qualifications");
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		
		if (!medicationProof) {
			setErrors(prev => ({ ...prev, medicationProof: "Please upload medication registration proof" }));
			return;
		}
		
		if (!identityProof) {
			setErrors(prev => ({ ...prev, identityProof: "Please upload identity proof" }));
			return;
		}

		try {
			const token = localStorage.getItem('accessToken');
			const formData = new FormData();

			// Add basic fields
			formData.append('name', initialValues.name);
			formData.append('bio', initialValues.bio);
			formData.append('phoneNumber', initialValues.phoneNumber);
			formData.append('email', initialValues.email);
			formData.append('isOfflineConsultationAvailable', isOfflineAvailable);
			formData.append('isOnlineConsultationAvailable', isOnlineAvailable);
			formData.append('consultationFee', initialValues.consultationFee);
			formData.append('gender', selectedOption);
			formData.append('age', initialValues.age);

			// Add specializations as array
			initialValues.specializations.forEach((spec, index) => {
				formData.append(`specializations[${index}][id]`, spec.id);
				formData.append(`specializations[${index}][experience]`, spec.experience);
			});

			// Add qualifications as array
			initialValues.qualifications.forEach((qual, index) => {
				formData.append(`qualifications[${index}][id]`, qual.id);
				formData.append(`qualifications[${index}][college]`, qual.college);
				formData.append(`qualifications[${index}][location]`, qual.location);
				formData.append(`qualifications[${index}][fromYear]`, qual.fromYear);
				formData.append(`qualifications[${index}][toYear]`, qual.toYear);
			});

			// Add files
			formData.append('medicationRegistrationProof', medicationProof);
			formData.append('identityProof', identityProof);

			console.log('123 formData:', formData);
			const response = await fetch(`${apiEndPoint}/doctors/create-doctor`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`
				},
				body: formData
			});

			const data = await response.json();
			if (data.msg === 'success') {
				toast.success("Doctor created successfully");
				setGetReloadData(true);
				setTimeout(() => navigate('/doctorsList'), 2000);
			} else {
				toast.error(data.message || "Failed to create doctor");
			}
		} catch (error) {
			console.error('Error creating doctor:', error);
			toast.error("Failed to create doctor");
		}
	};

	const handleReset = () => {
		setInitialValues({
			name: '',
			bio: '',
			phoneNumber: '',
			email: '',
			isOfflineConsultationAvailable: false,
			isOnlineConsultationAvailable: false,
			consultationFee: '',
			gender: '',
			age: '',
			specializations: [{ id: '', experience: '' }],
			qualifications: [{ id: '', college: '', location: '', fromYear: '', toYear: '' }]
		});
		setErrors({});
		setSelectedOption("");
		setIsOfflineAvailable(false);
		setIsOnlineAvailable(false);
		setMedicationProof(null);
		setIdentityProof(null);
	};

	return (
		<div className='dashboard'>
			<Sidebar isActive={isActive} />
			<div className='dashboardContainer'>
				<Navbar isActive={isActive} handleChange={() => setIsActive(!isActive)} />
				<div className="form-wrapper">
					<form className="form-container-doctor" onSubmit={handleSubmit} style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
						<h5 className="form-heading" style={{ marginBottom: '20px', textAlign: 'center' }}>Create new doctor</h5>
						
						<div className="form-group" style={{ marginBottom: '15px' }}>
							<label htmlFor="name" style={{ display: 'block', marginBottom: '5px' }}>Name:</label>
							<input
								type="text"
								id="name"
								name="name"
								value={initialValues.name}
								onChange={handleInputChange}
								placeholder="Enter Name"
								style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
							/>
							<div className="error" style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{errors.name}</div>
						</div>

						<div className="form-group" style={{ marginBottom: '15px' }}>
							<label htmlFor="bio" style={{ display: 'block', marginBottom: '5px' }}>Bio:</label>
							<textarea
								id="bio"
								name="bio"
								value={initialValues.bio}
								onChange={handleInputChange}
								placeholder="Enter Bio"
								style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd', minHeight: '100px' }}
							/>
							<div className="error" style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{errors.bio}</div>
						</div>

						<div className="form-group" style={{ marginBottom: '15px' }}>
							<label htmlFor="phoneNumber" style={{ display: 'block', marginBottom: '5px' }}>Phone Number:</label>
							<input
								type="tel"
								id="phoneNumber"
								name="phoneNumber"
								value={initialValues.phoneNumber}
								onChange={handleInputChange}
								placeholder="Enter Phone Number"
								style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
							/>
							<div className="error" style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{errors.phoneNumber}</div>
						</div>

						<div className="form-group" style={{ marginBottom: '15px' }}>
							<label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>Email:</label>
							<input
								type="email"
								id="email"
								name="email"
								value={initialValues.email}
								onChange={handleInputChange}
								placeholder="Enter Email"
								style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
							/>
							<div className="error" style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{errors.email}</div>
						</div>

						<div className="form-group" style={{ marginBottom: '15px' }}>
							<label htmlFor="consultationFee" style={{ display: 'block', marginBottom: '5px' }}>Consultation Fee:</label>
							<input
								type="text"
								id="consultationFee"
								name="consultationFee"
								value={initialValues.consultationFee}
								onChange={handleInputChange}
								placeholder="Enter Consultation Fee"
								style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
							/>
							<div className="error" style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{errors.consultationFee}</div>
						</div>

						<div className="form-group" style={{ marginBottom: '15px', display: 'flex', alignItems: 'center', gap: '10px' }}>
							<label htmlFor="isOfflineConsultationAvailable">Offline Consultation Available</label>
							<input
								type="checkbox"
								id="isOfflineConsultationAvailable"
								name="isOfflineConsultationAvailable"
								checked={isOfflineAvailable}
								onChange={handleCheckbox('offline')}
								style={{ margin: '0' }}
							/>
							<div className="error" style={{ color: 'red', fontSize: '12px' }}>{errors.offlineCheckbox}</div>
						</div>

						<div className="form-group" style={{ marginBottom: '15px', display: 'flex', alignItems: 'center', gap: '10px' }}>
							<label htmlFor="isOnlineConsultationAvailable">Online Consultation Available</label>
							<input
								type="checkbox"
								id="isOnlineConsultationAvailable"
								name="isOnlineConsultationAvailable"
								checked={isOnlineAvailable}
								onChange={handleCheckbox('online')}
								style={{ margin: '0' }}
							/>
							<div className="error" style={{ color: 'red', fontSize: '12px' }}>{errors.onlineCheckbox}</div>
						</div>

						<div className="form-group" style={{ marginBottom: '15px' }}>
							<label style={{ display: 'block', marginBottom: '5px' }}>Gender:</label>
							<div style={{ display: 'flex', gap: '20px' }}>
								{['male', 'female', 'other'].map(gender => (
									<div key={gender} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
										<input
											type="radio"
											name="gender"
											value={gender}
											checked={selectedOption === gender}
											onChange={handleOptionChange}
											style={{ margin: '0' }}
										/>
										<span>{gender.charAt(0).toUpperCase() + gender.slice(1)}</span>
									</div>
								))}
							</div>
							<div className="error" style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{errors.gender}</div>
						</div>

						<div className="form-group" style={{ marginBottom: '15px' }}>
							<label htmlFor="age" style={{ display: 'block', marginBottom: '5px' }}>Age:</label>
							<input
								type="text"
								id="age"
								name="age"
								value={initialValues.age}
								onChange={handleInputChange}
								placeholder="Enter Age"
								style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
							/>
							<div className="error" style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{errors.age}</div>
						</div>

						<div className="form-group" style={{ marginBottom: '15px' }}>
							<label style={{ display: 'block', marginBottom: '5px' }}>Specialization:</label>
							{initialValues.specializations.map((specialization, index) => (
								<div key={index} style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
									<select
										className="form-control"
										name={`specializations[${index}].id`}
										value={specialization.id}
										onChange={(e) => handleArrayInputChange(e, 'specializations', index)}
										style={{ flex: '2', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
									>
										<option value="">Select</option>
										{Array.isArray(specializationList) && specializationList.map((option) => (
											<option key={option.id} value={option.id}>
												{option.specialization}
											</option>
										))}
									</select>

									<input
										type="text"
										name={`specializations[${index}].experience`}
										value={specialization.experience}
										onChange={(e) => handleArrayInputChange(e, 'specializations', index)}
										placeholder="Experience"
										style={{ flex: '1', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
									/>
								</div>
							))}
							<div className="error" style={{ color: 'red', fontSize: '12px' }}>{errors.specializations}</div>
							<div className="error" style={{ color: 'red', fontSize: '12px' }}>{errors.experience}</div>
						</div>

						<div className="form-group" style={{ marginBottom: '15px' }}>
							<label style={{ display: 'block', marginBottom: '5px' }}>Qualification:</label>
							{initialValues.qualifications.map((qualification, index) => (
								<div key={index} style={{ display: 'flex', gap: '10px', marginBottom: '10px', flexWrap: 'wrap' }}>
									<select
										className="form-control"
										name={`qualifications[${index}].id`}
										value={qualification.id}
										onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
										style={{ flex: '1', minWidth: '200px', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
									>
										<option value="">Select</option>
										{Array.isArray(QualificationsList) && QualificationsList.map((option) => (
											<option key={option.id} value={option.id}>
												{option.qualification}
											</option>
										))}
									</select>

									<input
										type="text"
										name={`qualifications[${index}].college`}
										value={qualification.college}
										onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
										placeholder="College"
										style={{ flex: '1', minWidth: '200px', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
									/>

									<input
										type="text"
										name={`qualifications[${index}].location`}
										value={qualification.location}
										onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
										placeholder="Location"
										style={{ flex: '1', minWidth: '200px', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
									/>

									<input
										type="text"
										name={`qualifications[${index}].fromYear`}
										value={qualification.fromYear}
										onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
										placeholder="From Year"
										style={{ flex: '1', minWidth: '150px', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
									/>

									<input
										type="text"
										name={`qualifications[${index}].toYear`}
										value={qualification.toYear}
										onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
										placeholder="To Year"
										style={{ flex: '1', minWidth: '150px', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
									/>
								</div>
							))}
							<div className="error" style={{ color: 'red', fontSize: '12px' }}>{errors.qualificationId}</div>
							<div className="error" style={{ color: 'red', fontSize: '12px' }}>{errors.college}</div>
							<div className="error" style={{ color: 'red', fontSize: '12px' }}>{errors.location}</div>
							<div className="error" style={{ color: 'red', fontSize: '12px' }}>{errors.fromYear}</div>
							<div className="error" style={{ color: 'red', fontSize: '12px' }}>{errors.toYear}</div>
						</div>

						<div className="form-group" style={{ marginBottom: '15px' }}>
							<label htmlFor="medicationProof" style={{ display: 'block', marginBottom: '5px' }}>Medication Registration Proof:</label>
							<input
								type="file"
								id="medicationProof"
								onChange={(e) => handleFileChange(e, 'medication')}
								accept=".pdf,.jpg,.jpeg,.png"
								style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
							/>
							<div className="error" style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{errors.medicationProof}</div>
						</div>

						<div className="form-group" style={{ marginBottom: '15px' }}>
							<label htmlFor="identityProof" style={{ display: 'block', marginBottom: '5px' }}>Identity Proof:</label>
							<input
								type="file"
								id="identityProof"
								onChange={(e) => handleFileChange(e, 'identity')}
								accept=".pdf,.jpg,.jpeg,.png"
								style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
							/>
							<div className="error" style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{errors.identityProof}</div>
						</div>

						<div className="button-container" style={{ display: 'flex', gap: '10px', justifyContent: 'center', marginTop: '20px' }}>
							<button type="submit" className="button-savecls" style={{ 
								padding: '10px 20px', 
								borderRadius: '4px', 
								border: 'none',
								backgroundColor: '#4CAF50',
								color: 'white',
								cursor: 'pointer'
							}}>Save</button>
							<button type="button" className="button-resetcls" onClick={handleReset} style={{ 
								padding: '10px 20px', 
								borderRadius: '4px', 
								border: '1px solid #ddd',
								backgroundColor: 'white',
								cursor: 'pointer'
							}}>Reset</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default CreateDoctor;
