import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog } from 'primereact/dialog';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import ReviewNotes from './ReviewNotes';
import pdfImage from '../../TeleHealth/Images/pdfImage.png';
import NoImage from '../../TeleHealth/Images/NoImage.png';
import '../TeleHealth.css';
import './ReviewDoctor.css';

function ReviewDoctor() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isActive, setIsActive] = useState(false);
    const [doctorData, setDoctorData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showRejectDialog, setShowRejectDialog] = useState(false);

    const apiEndPoint = 'https://ciaitelehealth.com/api';

    useEffect(() => {
        if (!id) {
            const storedData = localStorage.getItem('doctorDetails');
            if (storedData) {
                const data = JSON.parse(storedData);
                setDoctorData(data);
                setLoading(false);
            } else {
                toast.error("No doctor data found");
                navigate('/doctorsList');
            }
        } else {
            getDoctorDetails();
        }
    }, [id]);

    const getDoctorDetails = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${apiEndPoint}/doctors/get-doctor-details/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch doctor details');
            }

            const result = await response.json();
            if (result && result.data) {
                setDoctorData(result.data[0]);
            } else {
                toast.error("Invalid data format received");
            }
        } catch (error) {
            console.error('Error fetching doctor details:', error);
            toast.error("Failed to load doctor details");
        } finally {
            setLoading(false);
        }
    };

    const handleApprove = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${apiEndPoint}/doctors/approve-doctor`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ doctorId: doctorData.id })
            });

            if (!response.ok) {
                throw new Error('Failed to approve doctor');
            }

            const data = await response.json();
            if (data.msg === "Doctor Approved Successfully") {
                toast.success("Doctor approved successfully");
                setTimeout(() => navigate('/doctorsList'), 2000);
            } else {
                toast.error(data.message || "Failed to approve doctor");
            }
        } catch (error) {
            console.error('Error approving doctor:', error);
            toast.error("Failed to approve doctor");
        }
    };

    const handleChange = () => {
        setIsActive(!isActive);
    };

    const renderDocument = (document, label) => {
        if (!document) {
            return (
                <div className="document-placeholder">
                    <img src={NoImage} alt="No Document" className="document-image" />
                    <span>No {label} available</span>
                </div>
            );
        }

        const isPDF = document.toLowerCase().endsWith('.pdf');
        
        if (isPDF) {
            return (
                <div 
                    className="pdf-preview" 
                    onClick={() => window.open(document, '_blank')}
                >
                    <img src={pdfImage} alt="PDF Document" />
                    <span>View PDF</span>
                </div>
            );
        }

        return (
            <div className="image-preview">
                <img 
                    src={document} 
                    alt={label}
                    className="document-image"
                    onClick={() => window.open(document, '_blank')}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = NoImage;
                    }}
                />
            </div>
        );
    };

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
                <div>Loading doctor details...</div>
            </div>
        );
    }

    if (!doctorData) {
        return (
            <div className="error-container">
                <div>No doctor data available</div>
                <button onClick={() => navigate('/doctorsList')}>Back to List</button>
            </div>
        );
    }

    return (
        <div className='dashboard'>
            <Sidebar isActive={isActive} />
            <div className='dashboardContainer'>
                <Navbar isActive={isActive} handleChange={handleChange} />
                <div className='review-doctor-container'>
                    <div className='review-header'>
                        <h2>Review Doctor Details</h2>
                    </div>

                    <div className='review-content'>
                        <div className='review-section personal-info'>
                            <h3>Personal Information</h3>
                            <div className='info-grid'>
                                <div className='info-item'>
                                    <label>Name</label>
                                    <span>{doctorData.name || 'N/A'}</span>
                                </div>
                                <div className='info-item'>
                                    <label>Gender</label>
                                    <span>{doctorData.gender || 'N/A'}</span>
                                </div>
                                <div className='info-item'>
                                    <label>Age</label>
                                    <span>{doctorData.age || 'N/A'}</span>
                                </div>
                                <div className='info-item'>
                                    <label>Experience (Years)</label>
                                    <span>{doctorData.experience || 'N/A'}</span>
                                </div>
                                <div className='info-item'>
                                    <label>Specialization</label>
                                    <span>{doctorData.specialization || 'N/A'}</span>
                                </div>
                                <div className='info-item'>
                                    <label>Bio</label>
                                    <span>{doctorData.bio || 'N/A'}</span>
                                </div>
                            </div>
                        </div>

                        <div className='review-section consultation-info'>
                            <h3>Consultation Details</h3>
                            <div className='info-grid'>
                                <div className='info-item'>
                                    <label>Consultation Fee</label>
                                    <span>₹{doctorData.consultationFee || '0'}</span>
                                </div>
                            </div>
                        </div>

                        <div className='review-section documents'>
                            <h3>Documents</h3>
                            <div className='documents-grid'>
                                <div className='document-item'>
                                    <label>Medical Registration</label>
                                    {renderDocument(doctorData.medicationRegistrationProof, 'Medical Registration')}
                                </div>
                                <div className='document-item'>
                                    <label>Identity Proof</label>
                                    {renderDocument(doctorData.identityProof, 'Identity Proof')}
                                </div>
                            </div>
                        </div>

                        <div className='review-actions'>
                            <button 
                                className='reject-button'
                                onClick={() => setShowRejectDialog(true)}
                            >
                                Reject
                            </button>
                            <button 
                                className='approve-button'
                                onClick={handleApprove}
                            >
                                Approve
                            </button>
                        </div>
                    </div>

                    <Dialog 
                        header="Reject Doctor"
                        visible={showRejectDialog}
                        style={{ width: '40vw' }}
                        onHide={() => setShowRejectDialog(false)}
                    >
                        <ReviewNotes 
                            visible={showRejectDialog} 
                            doctorID={doctorData.id}
                            setUserEdited={() => {
                                setShowRejectDialog(false);
                                navigate('/doctorsList');
                            }} 
                        />
                    </Dialog>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
}

export default ReviewDoctor;
