import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateSachivalayam(props) {
	const {setGetReloadData } = props;
	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';

	const [errors, setErrors] = useState({
		name: "",
		code: "",
		districtId: "",
		mandalId: "",
		anmName: "",
		anmPhoneNumber: ""
	});

	const [initialValues, setInitialValues] = useState({
		name: '',
		code: '',
		districtId: '',
		mandalId: '',
		anmName: '',
		anmPhoneNumber: ''
	});

    const [districts, setDistricts] = useState([]);
    const [mandals, setMandals] = useState([]);

	useEffect(() => {
		getDistrictsList();
	}, []);

	const getDistrictsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/districts/get-districts-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setDistricts(actualData.data);
		} catch (error) {
			console.error('Error fetching districts:', error);
			toast.error("Failed to fetch districts");
		}
	};

	const getMandalsList = async (districtId) => {
		if (!districtId) return;
		
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/mandals/get-active-mandals-list/${districtId}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setMandals(actualData.data);
		} catch (error) {
			console.error('Error fetching mandals:', error);
			toast.error("Failed to fetch mandals");
		}
	};

	const validateField = (name, value) => {
		let error = "";
		const phonePattern = /^[0-9]{10}$/;

		switch(name) {
			case "name":
				if (!value) {
					error = "Enter Sachivalayam Name";
				} else if (value.length < 3) {
					error = "Please enter at least 3 characters";
				} else if (value.length > 20) {
					error = "Please enter less than 20 characters";
				}
				break;

			case "districtId":
				if (!value) {
					error = "Select District";
				}
				break;

			case "mandalId":
				if (!value) {
					error = "Select Mandal Name";
				}
				break;

			case "anmName":
				if (!value) {
					error = "Enter ANM Name";
				} else if (value.length < 3) {
					error = "Please enter at least 3 characters";
				} else if (value.length > 50) {
					error = "Please enter less than 50 characters";
				}
				break;

			case "anmPhoneNumber":
				if (!value) {
					error = "Enter ANM Phone Number";
				} else if (!phonePattern.test(value)) {
					error = "Enter valid 10-digit number";
				}
				break;

			default:
				break;
		}

		setErrors(prev => ({
			...prev,
			[name]: error
		}));

		return error;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInitialValues(prev => ({
			...prev,
			[name]: value
		}));
		
		if (name === 'districtId') {
			setInitialValues(prev => ({
				...prev,
				mandalId: '' // Reset mandal when district changes
			}));
			getMandalsList(value);
		}
		
		validateField(name, value);
	};

	const validateForm = () => {
		let isValid = true;
		const fieldsToValidate = ['name', 'districtId', 'mandalId', 'anmName', 'anmPhoneNumber'];

		fieldsToValidate.forEach(field => {
			const error = validateField(field, initialValues[field]);
			if (error) {
				isValid = false;
			}
		});

		return isValid;
	};

	const CreateSachivalayamData = async () => {
		if (!validateForm()) {
			return;
		}

		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/sachivalayams/create-sachivalayam`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(initialValues),
			});

			const data = await response.json();
			
			if (data.msg === "Sachivalayam created successfully") {
				toast.success("Sachivalayam created successfully");
				setGetReloadData(true);
				props.setSachivalayamAdd();
				setTimeout(() => navigate('/sachivalayamsList'), 2000);
			} else {
				toast.error(data.message || "Failed to create sachivalayam");
			}
		} catch (error) {
			console.error('Error:', error);
			toast.error("Failed to create sachivalayam");
		}
	};
 
	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<form>
						<div className="form-group">
							<label>Sachivalayam Name</label>
							<input
								type='text'
								className='form-control'
								placeholder='Enter Sachivalayam Name'
								name='name'
								value={initialValues.name}
								onChange={handleChange}
							/>
							<div className="error">{errors.name}</div>
						</div>

						<div className="form-group">
							<label>Code</label>
							<input
								type='text'
								className='form-control'
								placeholder='Enter Code'
								name='code'
								value={initialValues.code}
								onChange={handleChange}
							/>
							<div className="error">{errors.code}</div>
						</div>

						<div className="form-group">
							<label>District</label>
							<select
								className="form-control"
								name='districtId'
								value={initialValues.districtId}
								onChange={handleChange}
							>
								<option value="">Select District</option>
								{districts.map((district) => (
									<option
										key={district.id}
										value={district.id}
									>
										{district.name}
									</option>
								))}
							</select>
							<div className="error">{errors.districtId}</div>
						</div>

						<div className="form-group">
							<label>Mandal Name</label>
							<select
								className="form-control"
								name='mandalId'
								value={initialValues.mandalId}
								onChange={handleChange}
								disabled={!initialValues.districtId}
							>
								<option value="">Select Mandal</option>
								{mandals.map((mandal) => (
									<option
										key={mandal.id}
										value={mandal.id}
									>
										{mandal.name}
									</option>
								))}
							</select>
							<div className="error">{errors.mandalId}</div>
						</div>

						<div className="form-group">
							<label>ANM Name</label>
							<input
								type='text'
								className='form-control'
								placeholder='Enter ANM Name'
								name='anmName'
								value={initialValues.anmName}
								onChange={handleChange}
							/>
							<div className="error">{errors.anmName}</div>
						</div>

						<div className="form-group">
							<label>ANM Phone Number</label>
							<input
								type='text'
								className='form-control'
								placeholder='Enter ANM Phone Number'
								name='anmPhoneNumber'
								value={initialValues.anmPhoneNumber}
								onChange={handleChange}
							/>
							<div className="error">{errors.anmPhoneNumber}</div>
						</div>

						<div className="button-container">
							<button 
								type="button" 
								className="button-cancle-cls" 
								onClick={() => props.setSachivalayamAdd()}
							>
								Cancel
							</button>
							<button 
								type="button" 
								className="button-savecls" 
								onClick={CreateSachivalayamData}
							>
								Save
							</button>
						</div>
					</form>
				</div>
			</div>
			<ToastContainer />
		</div>
	)
}

export default CreateSachivalayam;
