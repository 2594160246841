import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MenuProps, useStyles, options } from "./utils";
import { useNavigate } from 'react-router-dom';
import '../TeleHealth.css';
import { format } from 'date-fns-tz';
import TimezoneSelect, { allTimezones } from 'react-timezone-select'
import 'react-datetime-picker/dist/DateTimePicker.css';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Stack } from '@mui/material';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

export default function CreateSlotPage(props) {
    const { setGetReloadData, setUserAdded, doctorId, doctorName, cId } = props;
    const classes = useStyles();
    const navigate = useNavigate();
    const apiEndPoint = 'https://ciaitelehealth.com/api';

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [startTime, setStartTime] = useState(dayjs().startOf('day').add(9, 'hour'));
    const [endTime, setEndTime] = useState(dayjs().startOf('day').add(17, 'hour'));
    const [consultationType, setConsultationType] = useState("");
    const [errorConsultationType, setErrorConsultationType] = useState("");
    const [errorTime, setErrorTime] = useState("");

    const validateForm = () => {
        let isValid = true;
        
        if (!consultationType) {
            setErrorConsultationType("Select Consultation Type");
            isValid = false;
        }

        if (!startTime || !endTime) {
            setErrorTime("Select both start and end time");
            isValid = false;
        } else if (endTime.isBefore(startTime)) {
            setErrorTime("End time must be after start time");
            isValid = false;
        }

        return isValid;
    };

    const createSlotData = async () => {
        if (!validateForm()) return;

        const combinedStartDateTime = dayjs(selectedDate)
            .hour(startTime.hour())
            .minute(startTime.minute());

        const combinedEndDateTime = dayjs(selectedDate)
            .hour(endTime.hour())
            .minute(endTime.minute());

        const formatDateTime = (dt) => {
            return dt.format('YYYY-MM-DD HH:mm A');
        };

        const formData = {
            clinicId: cId,
            doctorId: doctorId,
            startTime: formatDateTime(combinedStartDateTime),
            endTime: formatDateTime(combinedEndDateTime),
            consultationType: consultationType,
            doctorTimeZone: "Asia/Kolkata",
        };

        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                toast.error("Authentication token not found");
                return;
            }

            const response = await fetch(`${apiEndPoint}/slots/create-slot`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (data.msg === 'Slot created successfully') {
                toast.success("Slot created successfully");
                setTimeout(() => {
                    setUserAdded();
                    setGetReloadData(true);
                    navigate(`/slotpage/${cId}/${doctorName}/${doctorId}`);
                }, 2000);
            } else if (data.msg === "slot already exists") {
                toast.error("Slot already exists");
            } else {
                toast.error(data.msg || "Failed to create slot");
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error("Failed to create slot");
        }
    };

    return (
        <div className='create-slot-container'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ marginBottom: '20px' }}>
                    <label className="font-bold block mb-2" style={{ marginLeft: "25px" }}>
                        Select Date:
                    </label>
                    <DatePicker
                        value={selectedDate}
                        onChange={(newDate) => setSelectedDate(newDate)}
                        sx={{ width: '100%', marginLeft: '20px', marginRight: '20px' }}
                    />
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <label className="font-bold block mb-2" style={{ marginLeft: "25px" }}>
                        Select Time Range:
                    </label>
                    <Stack direction="row" spacing={2} sx={{ marginLeft: '20px', marginRight: '20px' }}>
                        <TimePicker
                            label="Start Time"
                            value={startTime}
                            onChange={(newTime) => {
                                setStartTime(newTime);
                                setErrorTime("");
                            }}
                            sx={{ width: '50%' }}
                        />
                        <TimePicker
                            label="End Time"
                            value={endTime}
                            onChange={(newTime) => {
                                setEndTime(newTime);
                                setErrorTime("");
                            }}
                            sx={{ width: '50%' }}
                        />
                    </Stack>
                    {errorTime && <div className='error' style={{ color: 'red', marginLeft: '25px' }}>{errorTime}</div>}
                </div>

                <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label style={{ marginLeft: "25px" }}>Consultation Type:</label>
                    <select 
                        value={consultationType}
                        onChange={(e) => {
                            setConsultationType(e.target.value);
                            setErrorConsultationType("");
                        }}
                        className="form-control"
                        style={{ 
                            display: 'flex', 
                            width: '92%', 
                            marginLeft: '20px',
                            padding: '8px',
                            borderRadius: '4px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <option value="">Select</option>
                        <option value="online">Online</option>
                        <option value="offline">Offline</option>
                    </select>
                    {errorConsultationType && <div className='error' style={{ color: 'red', marginLeft: '25px' }}>{errorConsultationType}</div>}
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
                    <Button 
                        variant="contained" 
                        onClick={createSlotData}
                        style={{ backgroundColor: '#1976d2' }}
                    >
                        Save
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={() => setUserAdded()}
                        style={{ backgroundColor: '#d32f2f' }}
                    >
                        Cancel
                    </Button>
                </div>
            </LocalizationProvider>
            <ToastContainer />
        </div>
    );
}
