import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Login.css';

interface VerifyResponse {
    msg: string;
    passwordEnabled: boolean;
}

interface LoginResponse {
    msg: string;
    token: string;
    role: string;
}

const Login: React.FC = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState<number>(1);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [otp, setOtp] = useState<string>('');
    const [passwordEnabled, setPasswordEnabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const apiEndPoint = 'https://ciaitelehealth.com/api';

    const handlePhoneVerification = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(`${apiEndPoint}/login/verify-admin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phoneNumber })
            });

            const data: VerifyResponse = await response.json();
            if (response.ok) {
                setPasswordEnabled(data.passwordEnabled);
                setStep(data.passwordEnabled ? 2 : 3); // Go to password form or OTP form
            } else {
                toast.error(data.msg || 'Verification failed');
            }
        } catch (error) {
            toast.error('Failed to verify phone number');
        } finally {
            setLoading(false);
        }
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(`${apiEndPoint}/login/admin-login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phoneNumber, password })
            });

            const data: LoginResponse = await response.json();
            if (response.ok) {
                localStorage.setItem("accessToken", data.token);
                localStorage.setItem("messageStatus", data.msg);
                localStorage.setItem("userRole", data.role);
                navigate('/dashboard');
            } else {
                toast.error(data.msg || 'Login failed');
            }
        } catch (error) {
            toast.error('Login failed');
        } finally {
            setLoading(false);
        }
    };

    const handleOtpValidation = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(`${apiEndPoint}/login/admin-validate-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phoneNumber, otp })
            });

            const data = await response.json();
            if (response.ok) {
                setStep(4); // Go to set password form
            } else {
                toast.error(data.msg || 'Invalid OTP');
            }
        } catch (error) {
            toast.error('Failed to validate OTP');
        } finally {
            setLoading(false);
        }
    };

    const handleResendOtp = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiEndPoint}/login/admin-resend-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phoneNumber })
            });

            const data = await response.json();
            if (response.ok) {
                toast.success('OTP resent successfully');
            } else {
                toast.error(data.msg || 'Failed to resend OTP');
            }
        } catch (error) {
            toast.error('Failed to resend OTP');
        } finally {
            setLoading(false);
        }
    };

    const handleSetPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(`${apiEndPoint}/login/create-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phoneNumber, otp, password })
            });

            const data = await response.json();
            if (response.ok) {
                toast.success('Password set successfully');
                setTimeout(() => {
                    setStep(2); // Go back to login form
                }, 1500);
            } else {
                toast.error(data.msg || 'Failed to set password');
            }
        } catch (error) {
            toast.error('Failed to set password');
        } finally {
            setLoading(false);
        }
    };

    const renderPhoneForm = () => (
        <form onSubmit={handlePhoneVerification} className="login-form">
            <div className="logo-container">
                <img src="/telehealth_Logo.png" alt="Telehealth Logo" className="login-logo" />
            </div>
            <h2>Sign In</h2>
            <div className="form-group">
                <label>Phone Number</label>
                <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Enter your phone number"
                    required
                />
            </div>
            <button type="submit" disabled={loading}>
                {loading ? 'Please wait...' : 'Next'}
            </button>
        </form>
    );

    const renderPasswordForm = () => (
        <form onSubmit={handleLogin} className="login-form">
            <div className="logo-container">
                <img src="/telehealth_Logo.png" alt="Telehealth Logo" className="login-logo" />
            </div>
            <h2>Sign In</h2>
            <div className="form-group">
                <label>Phone Number</label>
                <input
                    type="tel"
                    value={phoneNumber}
                    disabled
                />
            </div>
            <div className="form-group">
                <label>Password</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    required
                />
            </div>
            <button type="submit" disabled={loading}>
                {loading ? 'Please wait...' : 'Sign In'}
            </button>
        </form>
    );

    const renderOtpForm = () => (
        <form onSubmit={handleOtpValidation} className="login-form">
            <div className="logo-container">
                <img src="/telehealth_Logo.png" alt="Telehealth Logo" className="login-logo" />
            </div>
            <h2>Verify OTP</h2>
            <div className="form-group">
                <label>Phone Number</label>
                <input
                    type="tel"
                    value={phoneNumber}
                    disabled
                />
            </div>
            <div className="form-group">
                <label>OTP</label>
                <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                    required
                />
            </div>
            <button type="submit" disabled={loading}>
                {loading ? 'Please wait...' : 'Verify OTP'}
            </button>
            <button 
                type="button" 
                className="resend-otp"
                onClick={handleResendOtp}
                disabled={loading}
            >
                Resend OTP
            </button>
        </form>
    );

    const renderSetPasswordForm = () => (
        <form onSubmit={handleSetPassword} className="login-form">
            <div className="logo-container">
                <img src="/telehealth_Logo.png" alt="Telehealth Logo" className="login-logo" />
            </div>
            <h2>Set Password</h2>
            <div className="form-group">
                <label>Phone Number</label>
                <input
                    type="tel"
                    value={phoneNumber}
                    disabled
                />
            </div>
            <div className="form-group">
                <label>Password</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter new password"
                    required
                />
            </div>
            <button type="submit" disabled={loading}>
                {loading ? 'Please wait...' : 'Set Password'}
            </button>
        </form>
    );

    return (
        <div className="login-container">
            {step === 1 && renderPhoneForm()}
            {step === 2 && renderPasswordForm()}
            {step === 3 && renderOtpForm()}
            {step === 4 && renderSetPasswordForm()}
            <ToastContainer />
        </div>
    );
};

export default Login;
