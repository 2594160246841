import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateMandal(props) {
	const { setGetReloadData } = props;
	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';

	const [errors, setErrors] = useState({
		name: "",
		districtId: ""
	});

	const [initialValues, setInitialValues] = useState({
		name: '',
		districtId: ''
	});

	const [districts, setDistricts] = useState([]);

	useEffect(() => {
		getDistrictsList();
	}, []);

	const getDistrictsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/districts/get-districts-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setDistricts(actualData.data);
		} catch (error) {
			console.error('Error fetching districts:', error);
			toast.error("Failed to fetch districts");
		}
	};

	const validateField = (name, value) => {
		let error = "";

		switch(name) {
			case "name":
				if (!value) {
					error = "Enter Mandal Name";
				} else if (value.length < 3) {
					error = "Please enter at least 3 characters";
				} else if (value.length > 50) {
					error = "Please enter less than 50 characters";
				}
				break;

			case "districtId":
				if (!value) {
					error = "Select District Name";
				}
				break;

			default:
				break;
		}

		setErrors(prev => ({
			...prev,
			[name]: error
		}));

		return error;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInitialValues(prev => ({
			...prev,
			[name]: value
		}));
		validateField(name, value);
	};

	const validateForm = () => {
		let isValid = true;
		Object.keys(initialValues).forEach(key => {
			const error = validateField(key, initialValues[key]);
			if (error) {
				isValid = false;
			}
		});
		return isValid;
	};

	const CreateMandalData = async () => {
		if (!validateForm()) {
			return;
		}

		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/mandals/create-mandal`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(initialValues),
			});

			const data = await response.json();
			
			if (data.msg === "Mandal created successfully") {
				toast.success("Mandal created successfully");
				setGetReloadData(true);
				props.setMandalAdd();
				setTimeout(() => navigate('/mandalsList'), 2000);
			} else {
				toast.error(data.message || "Failed to create mandal");
			}
		} catch (error) {
			console.error('Error:', error);
			toast.error("Failed to create mandal");
		}
	};

	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<form>
						<div className="form-group">
							<label>Mandal Name</label>
							<input
								type='text'
								className='form-control'
								name='name'
								placeholder='Enter Mandal Name'
								value={initialValues.name}
								onChange={handleChange}
							/>
							<div className="error">{errors.name}</div>
						</div>
						
						<div className="form-group">
							<label>District Name</label>
							<select
								className="form-control"
								name='districtId'
								value={initialValues.districtId}
								onChange={handleChange}
							>
								<option value="">Select District</option>
								{districts.map((district) => (
									<option
										key={district.id}
										value={district.id}
									>
										{district.name}
									</option>
								))}
							</select>
							<div className="error">{errors.districtId}</div>
						</div>

						<div className="button-container">
							<button 
								type="button" 
								className="button-cancle-cls"
								onClick={() => props.setMandalAdd()}
							>
								Cancel
							</button>
							<button 
								type="button" 
								className="button-savecls"
								onClick={CreateMandalData}
							>
								Save
							</button>
						</div>
					</form>
				</div>
			</div>
			<ToastContainer />
		</div>
	)
}

export default CreateMandal;
