import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import CreateCoupon from './CreateCoupon';
import UpdateCoupon from './UpdateCoupon';
import { ToastContainer, toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import '../TeleHealth.css';
import './Coupons.css';

function CouponsList() {
    const [isActive, setIsActive] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const apiEndPoint = 'https://ciaitelehealth.com/api';

    useEffect(() => {
        fetchCoupons();
    }, [searchTerm]);

    const fetchCoupons = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${apiEndPoint}/coupons/get-coupons?search=${searchTerm}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch coupons');
            }

            const data = await response.json();
            setCoupons(data.data || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching coupons:', error);
            toast.error('Failed to load coupons');
            setLoading(false);
        }
    };

    const handleDelete = (id) => {
        confirmDialog({
            message: 'Are you sure you want to delete this coupon?',
            header: 'Confirmation',
            accept: () => deleteCoupon(id)
        });
    };

    const deleteCoupon = async (id) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${apiEndPoint}/coupons/delete-coupon/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete coupon');
            }

            toast.success('Coupon deleted successfully');
            fetchCoupons();
        } catch (error) {
            console.error('Error deleting coupon:', error);
            toast.error('Failed to delete coupon');
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button 
                    icon="pi pi-pencil" 
                    title='Edit' 
                    rounded 
                    outlined 
                    className="mr-2 editBtnFocus" 
                    onClick={() => {
                        setSelectedCoupon(rowData);
                        setShowUpdateModal(true);
                    }}
                />
                <Button 
                    icon="pi pi-trash" 
                    title='Delete' 
                    rounded 
                    outlined 
                    className="deleteBtnFocus" 
                    onClick={() => handleDelete(rowData.id)} 
                />
            </React.Fragment>
        );
    };

    return (
        <div className='dashboard'>
            <Sidebar isActive={isActive} />
            <div className='dashboardContainer'>
                <Navbar isActive={isActive} handleChange={() => setIsActive(!isActive)} />
                <div className='users-page'>
                    <div className='container'>
                        <div className='users-list'>
                            <div className='list-header'>
                                <div className='header-users'>
                                    <h6>Coupons</h6>
                                </div>
                                <div className='header-actions'>
                                    <span className="p-input-icon-left search-input">
                                        <i className="pi pi-search" />
                                        <InputText 
                                            placeholder="Search coupons" 
                                            value={searchTerm}
                                            onChange={handleSearch}
                                        />
                                    </span>
                                    <button className='addUserCls' title='Add Coupon' onClick={() => setShowCreateModal(true)}>
                                        <i className='pi pi-plus' style={{paddingRight:'6px'}}></i> Create
                                    </button>
                                </div>
                            </div>

                            <DataTable value={coupons} paginator rows={10} loading={loading}>
                                <Column field="code" header="Code"  filter style={{ width: '10%' }}></Column>
                                <Column field="discountType" header="Discount Type" sortable filter style={{ width: '12%' }}></Column>
                                <Column field="discountValue" header="Value" sortable filter style={{ width: '8%' }}></Column>
                                <Column field="startTime" header="Validity" body={(rowData) => {return formatDate(rowData.startTime)+' to '+formatDate(rowData.expiryTime)}}  style={{ width: '12%' }}></Column>
                                <Column field="rule" header="Rule" sortable filter style={{ width: '12%' }}></Column>
                                <Column field="minPurchaseAmount" header="Min Purchase" body={(rowData) => `₹${rowData.minPurchaseAmount}`} sortable style={{ width: '12%' }}></Column>
                                <Column field="usageLimit" header="Usage Limit" sortable filter style={{ width: '12%' }}></Column>
                                <Column header="Actions" body={actionBodyTemplate} style={{ width: '15%' }}></Column>
                            </DataTable>
                        </div>
                    </div>

                    <Dialog 
                        header="Create New Coupon" 
                        visible={showCreateModal} 
                        style={{ width: '40vw' }} 
                        onHide={() => setShowCreateModal(false)}
                    >
                        <CreateCoupon
                            onSuccess={() => {
                                setShowCreateModal(false);
                                fetchCoupons();
                            }}
                            onClose={() => setShowCreateModal(false)}
                        />
                    </Dialog>

                    <Dialog 
                        header="Update Coupon" 
                        visible={showUpdateModal} 
                        style={{ width: '40vw' }} 
                        onHide={() => {
                            setShowUpdateModal(false);
                            setSelectedCoupon(null);
                        }}
                    >
                        {selectedCoupon && (
                            <UpdateCoupon
                                coupon={selectedCoupon}
                                onSuccess={() => {
                                    setShowUpdateModal(false);
                                    setSelectedCoupon(null);
                                    fetchCoupons();
                                }}
                                onClose={() => {
                                    setShowUpdateModal(false);
                                    setSelectedCoupon(null);
                                }}
                            />
                        )}
                    </Dialog>

                    <ConfirmDialog />
                    <ToastContainer />
                </div>
            </div>
        </div>
    );
}

export default CouponsList;
