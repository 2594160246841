import React, { useState } from "react";
import axios from "axios";
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';
import './Coupons.css';

const CreateCoupon = ({ onClose, onSuccess }) => {
  const [couponData, setCouponData] = useState({
    code: "",
    description: "",
    discountType: "percentage",
    discountValue: "",
    maxDiscount: "",
    rule: "first-time",
    minPurchaseAmount: "",
    usageLimit: "",
    perUserLimit: "",
  });
  const [startTime, setStartTime] = useState(new Date());
  const [expiryTime, setExpiryTime] = useState(new Date());

  const discountTypes = [
    { label: 'Percentage', value: 'percentage' },
    { label: 'Fixed Amount', value: 'fixed' }
  ];

  const rules = [
    { label: 'First Time', value: 'first-time' },
    { label: 'Everyone', value: 'everyone' }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        ...couponData,
        startTime: startTime.toLocaleDateString('en-US'),
        expiryTime: expiryTime.toLocaleDateString('en-US'),
      };

      const token = localStorage.getItem('accessToken');
      const response = await axios.post(
        'https://ciaitelehealth.com/api/coupons/create-coupon',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 201) {
        toast.success('Coupon created successfully');
        onSuccess();
      }
    } catch (error) {
      console.error("Error creating coupon:", error);
      toast.error('Failed to create coupon');
    }
  };

  const handleChange = (name, value) => {
    setCouponData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="p-fluid">
      <div className="form-group">
        <label htmlFor="code">Coupon Code</label>
        <InputText
          id="code"
          value={couponData.code}
          onChange={(e) => handleChange('code', e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="description">Description</label>
        <InputTextarea
          id="description"
          value={couponData.description}
          onChange={(e) => handleChange('description', e.target.value)}
          rows={3}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="discountType">Discount Type</label>
        <Dropdown
          id="discountType"
          value={couponData.discountType}
          options={discountTypes}
          onChange={(e) => handleChange('discountType', e.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="discountValue">Discount Value</label>
        <InputNumber
          id="discountValue"
          value={couponData.discountValue}
          onValueChange={(e) => handleChange('discountValue', e.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="maxDiscount">Maximum Discount</label>
        <InputNumber
          id="maxDiscount"
          value={couponData.maxDiscount}
          onValueChange={(e) => handleChange('maxDiscount', e.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="startTime">Start Time</label>
        <Calendar
          id="startTime"
          value={startTime}
          onChange={(e) => setStartTime(e.value)}
          showIcon
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="expiryTime">Expiry Time</label>
        <Calendar
          id="expiryTime"
          value={expiryTime}
          onChange={(e) => setExpiryTime(e.value)}
          showIcon
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="rule">Rule</label>
        <Dropdown
          id="rule"
          value={couponData.rule}
          options={rules}
          onChange={(e) => handleChange('rule', e.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="minPurchaseAmount">Minimum Purchase Amount (₹)</label>
        <InputNumber
          id="minPurchaseAmount"
          value={couponData.minPurchaseAmount}
          onValueChange={(e) => handleChange('minPurchaseAmount', e.value)}
          mode="currency"
          currency="INR"
          locale="en-IN"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="usageLimit">Usage Limit</label>
        <InputNumber
          id="usageLimit"
          value={couponData.usageLimit}
          onValueChange={(e) => handleChange('usageLimit', e.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="perUserLimit">Per User Limit</label>
        <InputNumber
          id="perUserLimit"
          value={couponData.perUserLimit}
          onValueChange={(e) => handleChange('perUserLimit', e.value)}
          required
        />
      </div>

      <div className="dialog-footer">
        <Button label="Cancel" icon="pi pi-times" onClick={onClose} className="p-button-text" />
        <Button label="Create" icon="pi pi-check" type="submit" autoFocus />
      </div>
    </form>
  );
};

export default CreateCoupon;
